.alice-carousel__wrapper {
    max-height: 350px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-wrap: nowrap;
}

.carousel-item {
    position: relative;
    width: 100%;
    padding-bottom: calc(100% * 16 / 9); 
    overflow: hidden;
}

.carousel-item > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0 5px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
} 

.carousel-item > div > * {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.alice-carousel__dots-item:not(.__custom) {
    width: 15px !important;
    height: 15px !important;
}

.alice-carousel__dots-item:not(.__custom):hover, .alice-carousel__dots-item:not(.__custom).__active {
    background-color: #ff2b80 !important;
}

.alice-carousel__dots {
    margin-bottom: 20px;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .flamingo-bg-steps {
  width: 100%;
  background-image: url('http://flamingo.misterfront.com/wp-content/uploads/2023/03/fondo-1900x1180-min.jpg');
  background-repeat: no-repeat;
  background-size: contain;
} */

/* .flg-toggle-button-hour.Mui-disabled {
  border: none !important;
}

.flg-toggle-button-hour.Mui-disabled > .box-cst-btn {
  margin-bottom: auto;
  background-color: transparent;
  border-color: #ffffff;
} 

.flg-toggle-button-hour.Mui-disabled > .box-cst-btn > .tg-box-left {
  display: none;
}

.flg-toggle-button-hour.Mui-disabled > .box-cst-btn > .btn-cst-radio {
  background-color: transparent;
  color: #ffffff;
} */

/* REMOVE FROM HERE */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

@import url("https://fonts.googleapis.com/css2?family=Lato&family=Poppins:wght@400;500;700&display=swap");

.flamingo-bg-steps .MuiPaper-elevation0 {
  background: transparent;
}

input#outlined-controlled {
  padding: 0.5rem 1rem 0.5rem 1rem !important;
  width: 8px !important;
  border: none !important;
}

.flg-box-count {
  background-color: #fff;
  margin-left: 20px;
}

/* Accordion */

.MuiAccordion-root.MuiPaper-elevation0,
.fmg-ac__title {
  border: none;
}

.MuiAccordion-root.Mui-expanded {
  border: none !important;
}

.fmg-ac__title .MuiAccordionSummary-content {
  justify-content: center;
  align-items: center;
}

.fmg-ac__title .MuiTypography-root {
  display: flex;
  align-items: center;
}

.fmg-ac__title .MuiTypography-root span {
  margin-right: 0.5em;
}

.fmg-ac__title .MuiAccordionSummary-expandIconWrapper {
  display: none !important;
}

/* calendar */

.flg-container2 {
  display: flex;
  justify-content: center;
}

.toggle-button {
  max-width: 320px;
  display: grid !important;
  grid-template-columns: repeat(4, auto);
  grid-column-gap: 13px;
}

.toggle-button button {
  border-radius: 0;
  padding: 0 !important;
  margin-bottom: 10px;
}

.toggle-button button.Mui-selected .btn-cst-radio,
.toggle-button button .btn-cst-radio:hover {
  background-color: #ff2b80;
  color: #fff;
}

.box-cst-btn {
  background-color: #5b2b91;
  color: #fff;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.3);
}

.btn-cst-radio {
  padding: 2px 16px;
  background-color: #fff;
  color: #4d4d4d;
}

.flg-toggle-button-hour {
  border: none !important;
}
.flg-toggle-button-hour.Mui-disabled {
  border: none !important;
}

.flg-toggle-button-hour > .box-cst-btn {
  margin-bottom: auto;
}

.flg-toggle-button-hour.Mui-disabled > .box-cst-btn {
  margin-bottom: auto;
  background-color: transparent;
  border-color: #ffffff;
}

.flg-toggle-button-hour.Mui-disabled > .box-cst-btn > .tg-box-left {
  display: none;
}

.flg-toggle-button-hour.Mui-disabled > .box-cst-btn > .btn-cst-radio {
  background-color: transparent;
  color: #ffffff;
}

/* Buttons */
.fmb-btn-next {
  width: auto !important;
  border-radius: 0 !important;
  background-color: #ff2b80;
  color: #fff;
  margin-top: 30px !important;
  max-height: 46px !important;
}

/* Detail Checout */
.flg-detail-box__bg {
  margin-top: 30px;
  padding-top: 20px;
  padding-bottom: 24px;
  background-color: #29ccc7;
  color: white;
  font-size: 1.3rem;
}

.flg-detail-box__bg p {
  margin-top: 0;
  color: #fff;
}

.detail-product-checkout,
.flg-all-prince {
  display: flex;
  justify-content: space-between;
}

.flg-all-prince {
  color: #29ccc7;
  border-bottom: 2px solid #29ccc7;
}

.flg-checkout-steps {
  margin-top: 60px;
}

/* Services & Duration */

/* information */

.flamingo-info-form p {
  max-width: 345px;
  margin-inline: auto;
  color: white;
  font-family: "Poppins";
}

.flamingo-info-wraper {
  display: flex;
}

.flamingo-info-wraper .MuiInputBase-fullWidth {
  border-radius: 0;
  margin-bottom: 20px;
}

.info-wraper-box1 {
  padding-right: 10px;
}

.info-wraper-box2 {
  padding-left: 10px;
}

.flg-box-counter {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.flg-touch-container-hours {
  display: contents !important;
}

button.flg-touch-button-hours {
  padding: 0 !important;
  border: none;
  text-transform: inherit;
}

.flg-touch-button-hours.actived {
  color: #29ccc7;
  font-weight: 700;
  /* font-size: 120%; */
}

/* button.fmb-btn-next {
    
  } */

.flg-cnt-btn__container {
  width: 100%;
  display: flex;
}

.flg-cnt-btn__txt {
  width: 85%;
  padding: 11px 35px 5px 35px;
}

.MuiGrid2-root.MuiGrid2-direction-xs-row.MuiGrid2-grid-md-5.css-113daup {
  background: white;
  padding: 2rem;
}

.MuiGrid2-root.MuiGrid2-direction-xs-row.MuiGrid2-grid-md-7.flamingo-bg-steps.css-1o4tv1o-MuiGrid2-root:after {
  content: "";
  display: block;
  width: 210px;
  height: 80px;
  background-image: url(https://pruebas.upideas.us/flamingo-boat/ride-the-wave-200x80.png);
  background-repeat: no-repeat;
  position: absolute;
  bottom: 160px;
  right: calc(100% * 5.25 / var(--Grid-columns));
}

.MuiContainer-root.MuiContainer-maxWidthXl.css-19r6kue-MuiContainer-root {
  position: relative;
}

.flg-detail-box__bg p {
  color: white;
  line-height: normal;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0;
}

p.MuiTypography-root.MuiTypography-body1 {
  color: #44206d;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.flg-select-product.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  background: white;
}

.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0.5rem 3rem 0.5rem 2rem;
}

input#outlined-controlled {
  padding: 0.5rem 3rem 0.5rem 2rem;
  width: 50px;
  text-align: center;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  background: white;
}

.MuiFormControl-root > div {
  background: white;
}

th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeSmall.css-13njpue {
  background: #29ccc7;
  color: white;
  text-align: center;
  font-size: 1rem;
}

table.MuiTable-root.css-18qulp9 * {
  font-family: "Poppins", sans-serif;
}

span.counter-label {
  color: white;
  font-family: "Poppins", sans-serif;
}

.css-16nxph9:nth-child(1) td {
  font-size: 0.9rem;
  background: #f2f2f2;
  padding: 0.8rem 0;
}

.css-16nxph9:nth-child(2) td {
  font-size: 0.8rem;
  padding: 1rem 0;
}

.css-q5cu77:hover {
  text-decoration: none;
  color: #29ccc7;
  background-color: transparent !important;
}

.Counter_button__PhvJ- {
  background-color: transparent !important;
  border: 2px solid transparent;
}

.css-qiwgdb {
  padding: 11.5px 14px !important;
}
.css-1hw9j7s {
  padding: 0 !important;
}

span.flg-cnt-btn__container--svg {
  background-color: #2e3d5c;
  text-align: center;
  width: auto;
  padding: 13px 9px 2px 19px;
}

span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label.css-9l3uo3 {
  text-align: left;
  line-height: normal;
  font-size: 0.9rem;
}

#root > div > div > div > div {
  position: relative;
}

.css-b1884d {
  min-width: 220px !important;
  text-align: left;
}

.css-1ujsas3 {
  padding: 0 !important;
}

.css-1k33q06 {
  width: 20px !important;
  font-size: 1.25rem;
}

.MuiPickersCalendarHeader-root.css-1dozdou {
  background: #29ccc7;
  color: white;
  margin-bottom: 0;
  padding: 8px;
}

.css-1bx5ylf {
  display: block;
  position: relative;
  font-family: "Poppins", sans-serif;
}

.MuiDayCalendar-header.css-1n2mv2k span {
  color: #29ccc7;
}

.MuiButtonBase-root.Mui-selected {
  background-color: #29ccc7 !important;
  border-radius: 0 !important;
}
.css-84ri5h:not(.Mui-selected) {
  border-radius: 0 !important;
}

.flamingo-calendar {
  padding-bottom: 10px;
}

.flamingo-calendar p,
.flamingo-calendar ul li {
  color: white;
  max-width: 80%;
  margin-left: 10%;
  text-align: left;
  line-height: 1.2;
  list-style: square;
}

.MuiCollapse-entered .css-1fpbxjw {
  padding: 16px;
  border-top: 1px solid white !important;
}

.css-i44wyl {
  margin: 7px !important;
  width: 100%;
}

.fmg-ac__detail .MuiTableContainer-root {
  border-radius: 0;
}

.flg-step-label__icon svg {
  width: 15px;
}

.flg-mobile-your-booking {
  background: #44206d;
  color: white;
  bottom: 0;
  width: 300px;
  line-height: 1;
  padding: 0.5rem;
  position: relative;
  margin: 130px auto;
}

.MuiPickersFadeTransitionGroup-root.MuiDateCalendar-viewTransitionContainer.css-1h73gvd {
  background-color: white;
}
.MuiButtonBase-root:hover {
  background-color: rgba(25, 118, 210, 0.04);
  border-radius: 0;
}
button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd.MuiIconButton-sizeMedium.MuiPickersArrowSwitcher-button.css-11wxb,
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeInherit.css-1cw4hi4 {
  color: white;
}

.flamingo-calendar ul {
  padding: 0;
}
button.MuiButtonBase-root,
button.MuiButtonBase-root:focus,
button.MuiButtonBase-root:focus-visible,
button.MuiButtonBase-root:focus-within {
  border-radius: 0 !important;
}
button.MuiButtonBase-root.MuiToggleButton-root {  font-weight: 800;}
button.MuiButtonBase-root span {
  border-radius: 0 !important;
}

.info-under-calendar {
  background: #29ccc7;
  width: 320px;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.flamingo-reserve {
  padding-left: 3rem;
}

button.MuiButtonBase-root.MuiButton-root.fmb-btn-next.fmb-btn-next-disabled {
  background: white !important;
  color: #ffb8d5 !important;
  border: none !important;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.Mui-disabled.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.fmb-btn-next.fmb-btn-next-disabled
  .flg-cnt-btn__container--svg {
  border: solid 2px #ffb8d5 !important;
  background: white;
  width: 26px;
  background-image: url(https://pruebas.upideas.us/flamingo-boat/icono-bolsa-deshabilitado.png);
  background-position: center;
  background-repeat: no-repeat;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.Mui-disabled.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.fmb-btn-next.fmb-btn-next-disabled
  .flg-cnt-btn__txt {
  border: solid 2px #ffb8d5 !important;
  border-right: none !important;
}

.MuiGrid2-root.MuiGrid2-direction-xs-row.MuiGrid2-grid-md-5.css-113daup:before {
  content: "";
  display: block;
  width: 201px;
  height: 80px;
  bottom: 70px;
  position: absolute;
  background-image: url(https://pruebas.upideas.us/flamingo-boat/ride-the-wave-200x80.png);
  background-repeat: no-repeat;
  margin-left: calc(50% - 100px);
}

.MuiGrid2-root.MuiGrid2-direction-xs-row.MuiGrid2-grid-md-5.css-113daup {
  position: relative;
}

.flg-step-connector__active {
}
.flg-step-connector__active-line {
}
.flg-step-connector__completed {
}
.flg-step-connector__completed {
}
.flg-step-connector__line {
}
.flg-sptepper-container__x1 {
}
.flg-sptepper-step__x1 {
}
.flg-sptepper-label__x1:before {
  content: "";
  display: block;
  width: 30px;
  height: 30px;
  margin-bottom: 1rem;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 29px;
}

.MuiStepper-root.MuiStepper-horizontal.MuiStepper-alternativeLabel.flg-sptepper-container__x1.css-1187icl
  > div:nth-child(1)
  .flg-sptepper-label__x1:before {
  background-image: url(https://pruebas.upideas.us/flamingo-boat/icono-reloj-color.png);
}

.MuiStepper-root.MuiStepper-horizontal.MuiStepper-alternativeLabel.flg-sptepper-container__x1.css-1187icl
  > div:nth-child(2)
  .flg-sptepper-label__x1:before {
  background-image: url(https://pruebas.upideas.us/flamingo-boat/icono-calendario-color.png);
}

.MuiStepper-root.MuiStepper-horizontal.MuiStepper-alternativeLabel.flg-sptepper-container__x1.css-1187icl
  > div:nth-child(3)
  .flg-sptepper-label__x1:before {
  background-image: url(https://pruebas.upideas.us/flamingo-boat/icono-info-color.png);
}

.MuiStepper-root.MuiStepper-horizontal.MuiStepper-alternativeLabel.flg-sptepper-container__x1.css-1187icl
  > div:nth-child(4)
  .flg-sptepper-label__x1:before {
  background-image: url(https://pruebas.upideas.us/flamingo-boat/icono-bolsa-color.png);
}

.MuiFormGroup-root.css-1h7anqn {
  margin-top: 1rem;
}

.MuiDateCalendar-root.css-1080di7 > div:nth-child(2) {
  background: white;
}

.flg-stripe-info__container {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
}

.flg-stripe-info__container p {
  margin: 0;
}
.flg-stripe-info__container label {
  font-size: 12px;
  color: #666666;
  font-variant: all-small-caps;
}

.flg-stripe_info {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: baseline;
  margin: 2px 0;
}

#payment-element {
  border: 1px solid #666666;
  padding: 10px;
  border-radius: 5px;
}

.modal-checkout-box {
  max-width: 725px;
}

@media screen and (min-width: 480px) {
  .flamingo-mobile-checkout {
    display: none;
  }

  .flamingo-container {
    min-height: 100vh;
  }
  .MuiGrid2-root.MuiGrid2-direction-xs-row.MuiGrid2-grid-md-5.css-113daup {
    min-height: 100vh;
  }
  .flamingo-container {
    background-image: url(https://pruebas.upideas.us/flamingo-boat/fondo-1900x1180b-min.jpg);
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: auto;
    background-color: #1db7ba;
  }
  .MuiAccordionSummary-content.Mui-expanded
    p.MuiTypography-root.MuiTypography-body1 {
    font-size: 30px;
  }
  .title-flg-gallery__size {
    font-size: 30px !important;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    margin: 30px 0 !important;
  }
  .MuiAccordionSummary-content:not(.Mui-expanded)
    p.MuiTypography-root.MuiTypography-body1 {
    font-size: 22px;
    opacity: 0.6;
  }

  .MuiGrid2-root.MuiGrid2-direction-xs-row.MuiGrid2-grid-md-7.flamingo-bg-steps.css-19qe0vp {
    padding: 3rem 3rem 0 0;
    /* min-height: 930px; */
  }
  .box-products {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 0 2em 0;
  }
  .flg-cenefa__logos,
  .flg-mobile-your-booking {
    display: none;
  }
  .flamingo-detail-checkout {
    margin-bottom: 10rem;
  }
}
@media screen and (min-width: 768) {
  .modal-checkout-box {
    min-width: 725px;
  }
}
@media screen and (max-width: 767) {
  .flg-stripe_info {
    width: 100%;
  }
}
@media screen and (max-width: 480px) {
  body {
    background-image: url(https://pruebas.upideas.us/flamingo-boat/fondo-600x1179c-min.jpg);
    background-position: bottom left;
    /* min-height: 830px; */
    /* background-size: 1200px; */
    background-repeat: no-repeat;
    background-color: #1db7ba;
  }

  .MuiGrid2-root.MuiGrid2-direction-xs-row.MuiGrid2-grid-md-5.css-113daup {
    display: none;
  }

  p.MuiTypography-root.MuiTypography-body1 {
    font-size: 22px;
  }

  p.MuiTypography-root.MuiTypography-body1 span img {
    width: 18px;
  }
  .MuiCollapse-entered .css-1fpbxjw {
    padding: 0 !important;
  }
  .box-products {
    display: flex;
    flex-direction: column;
  }
  .css-18qulp9 {
    min-width: auto !important;
  }
  .flg-cenefa__logos {
    background: #ff2b80;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 0 10px;
    margin-inline: -15px;
    margin-bottom: 2rem;
  }
  .flamingo-calendar li {
    text-align: left;
    color: white;
    list-style: none;
  }
  .flg-container2 {
    flex-direction: column;
  }
  .flg-mobile-your-booking__container {
    position: sticky;
    bottom: 0;
    width: 100%;
    background: #44206d;
  }
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters.flg-mobile-your-booking.css-67l5gl {
    border: none;
    background: none;
    box-shadow: none;
    margin: 0;
    width: 100%;
    padding: 1rem 1rem 1rem 0rem;
  }

  p.MuiTypography-root.MuiTypography-body1.css-9l3uo3 {
    color: white;
  }

  .MuiAccordionSummary-content.MuiAccordionSummary-contentGutters.css-17o5nyn {
    flex-grow: 0;
    margin: 0;
    padding: 0;
  }

  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-vubbuv {
    color: white;
  }

  div#panel1a-header {
    padding: 0;
  }

  .flg-mobile-box__bg p {
    margin: 0;
    line-height: normal;
    color: white;
  }

  .flg-mobile-box__bg strong {
    color: white;
  }

  .MuiFormGroup-root.css-1h7anqn {
    color: white;
  }
  .MuiFormGroup-root.css-1h7anqn a {
    color: white;
  }

  .info-under-calendar {
    margin: auto;
  }
}
.flg-desktop-checkout__bg {
  background: white;
  padding: 2rem 4rem !important;
}

.flg-terms-checkout label {
  text-align: left;
}

.flg-sptepper-step__x1:nth-child(1) .flg-sptepper-label__x1:before {
  background-image: url(https://pruebas.upideas.us/flamingo-boat/icono-reloj-color.png);
}
.flg-sptepper-step__x1:nth-child(2) .flg-sptepper-label__x1:before {
  background-image: url(https://pruebas.upideas.us/flamingo-boat/icono-calendario-color.png);
}
.flg-sptepper-step__x1:nth-child(3) .flg-sptepper-label__x1:before {
  background-image: url(https://pruebas.upideas.us/flamingo-boat/icono-info-color.png);
}
.flg-sptepper-step__x1:nth-child(4) .flg-sptepper-label__x1:before {
  background-image: url(https://pruebas.upideas.us/flamingo-boat/icono-bolsa-color.png);
}

.flamingo-detail-checkout button.Mui-disabled.fmb-btn-next {
  background: white;
  border: 2px solid #ffb8d5 !important;
  color: #ffb8d5 !important;
  margin-top: 2rem !important;
}

.flamingo-detail-checkout button.Mui-disabled .flg-cnt-btn__container {
  background: transparent;
}

.flamingo-detail-checkout button.Mui-disabled span.flg-cnt-btn__container--svg {
  background-image: url(https://pruebas.upideas.us/flamingo-boat/icono-bolsa-deshabilitado.png);
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
/*.flamingo-detail-checkout
    button.Mui-disabled
    span.flg-cnt-btn__container--svg:before {
    content: "";
    display: block;
    height: calc(100% + 2px);
    width: 2px;
    background: #ffb8d5;
    left: -10px;
    bottom: -1px;
    position: absolute;
  } */

/* Countdown */

.timer-wrapper {
  display: flex;
  justify-content: center;
}

.timer {
  font-family: "Montserrat";
  display: flex;
  flex-direction: column;
  align-items: center;
}

.timer--text {
  color: #aaa;
}

.timer--value {
  font-size: 14px;
}

.timer--info {
  max-width: 55;
  margin: 10px auto 0;
  text-align: center;
  font-size: 12px;
}

.coutdown-wrapper {
  display: flex;
  align-items: center;
  background-color: #ff2b80;
  padding: 16px;
}

.coutdown--info {
  text-align: left;
  padding-right: 30px;
}

@media screen and (min-width: 480px) {
  .countdown--mobile {
    display: none !important;
  }
}

#accordion-panel1 button.MuiButtonBase-root.MuiButton-root.fmb-btn-next {
}

#panel1d-content .flg-touch-container-hours .flg-touch-button-hours.actived {
  font-size: 120%;
}

.flamingo-container
  div[id^="accordion-panel"]
  button.MuiButtonBase-root.MuiButton-root.fmb-btn-next {
  background: rgb(255, 43, 128);
}

.flamingo-container .flamingo-reserve {
  width: 50%;
  padding-top: 14px;
}

.MuiOutlinedInput-root.Mui-focused {
}

.MuiInputBase-root.Mui-focused {
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.Mui-focused.MuiInputBase-formControl.css-1bp1ao6
  input {
  border-color: purple;
}

.coutdown-wrapper p,
.coutdown-wrapper p b {
  color: white;
}

.timer {
  color: white;
}
.flamingo-info-form .Mui-error {
  color: white;
  font-size: 1rem;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.fmb-btn-next:not(
    .Mui-disabled
  ) {
  background: #ff2b80;
  color: white;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.fmb-btn-next:not(
    .Mui-disabled
  )
  .flg-cnt-btn__container--svg {
  background: #29ccc7;
  background-image: url(https://pruebas.upideas.us/flamingo-boat/icono-cuadrado-boton-bolsa-blanco-negro.png);
  width: 22px;
  background-repeat: no-repeat;
  background-size: contain;
}

.modal-checkout-box.MuiBox-root button#submit {
  background: #ff2b80;
  color: white;
}

.modal-checkout-box.MuiBox-root button#submit span.flg-cnt-btn__container--svg {
  background: #29ccc7;
  height: 30px;
  text-align: center;
  border: 0;
  padding: 8px 8px 2px 10px;
}

.modal-checkout-box.MuiBox-root button#submit svg.MuiSvgIcon-root {
  width: auto;
}

.MuiModal-root .modal-checkout-box.MuiBox-root {
  border: none;
}

.modal-checkout-box h4 {
  color: #29ccc7;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.fmb-btn-next:not(
    .Mui-disabled
  ) {
  background: #ff2b80;
  color: white;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.fmb-btn-next:not(
    .Mui-disabled
  )
  .flg-cnt-btn__container--svg {
  background: #29ccc7;
  background-image: url(https://pruebas.upideas.us/flamingo-boat/icono-cuadrado-boton-bolsa-blanco-negro.png);
  width: 22px;
  background-repeat: no-repeat;
  background-size: contain;
}

.modal-checkout-box.MuiBox-root button#submit {
  background: #ff2b80;
  color: white;
}

.modal-checkout-box.MuiBox-root button#submit span.flg-cnt-btn__container--svg {
  background: #29ccc7;
  height: 30px;
  text-align: center;
  border: 0;
  padding: 9px 10px 2px 10px;
}

.modal-checkout-box.MuiBox-root button#submit svg.MuiSvgIcon-root {
  width: auto;
}

.MuiModal-root .modal-checkout-box.MuiBox-root {
  border: none;
}

.modal-checkout-box h4 {
  color: #29ccc7;
  font-size: 1.2rem;
}

.modal-checkout-box label {
  color: #29ccc7;
  font-size: 1rem;
}
.modal-checkout-box #payment-message .result-message span {
  color: white;
  background: #ff2b80;
  padding: 0.3rem 1rem 0.5rem;
}
.modal-checkout-box #payment-message .refresh-message {
  color: #29ccc7;
}

@media screen and (min-width: 480px) {
  .flamingo-calendar .flamingo-reserve {
    width: 50%;
  }
}
@media screen and (max-width: 480px) {
  .countdown--mobile {
    margin-bottom: 1rem;
  }
  .box-products {
    margin-bottom: 1.5rem;
  }
  div#accordion-panel2,
  div#accordion-panel3 {
    margin-bottom: 1.5rem;
  }
}

.MuiOutlinedInput-root fieldset {
  border: 0;
}

.MuiOutlinedInput-root:hover fieldset {
  border: 0;
}

.MuiOutlinedInput-root.Mui-focused fieldset {
  border: 0;
}

.flg-proccessing-box {
  text-align: center;
}

.Mui-error input {
  color: #000000de !important;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.fmb-btn-next:not(
    .Mui-disabled
  ) {
  background: #ff2b80;
  color: white;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.fmb-btn-next:not(
    .Mui-disabled
  )
  .flg-cnt-btn__container--svg {
  background: #29ccc7;
  background-image: url(https://pruebas.upideas.us/flamingo-boat/icono-cuadrado-boton-bolsa-blanco-negro.png);
  width: 22px;
  background-repeat: no-repeat;
  background-size: contain;
}

.MuiGrid-root p.Mui-error {
  background: #44206d;
  padding: 0 0.3rem;
  color: white;
}

.flg-message-select-date span {
  color: white;
}

.flg-message-select-date span:before {
  content: "<";
  color: #44206d;
  font-weight: 900;
  transform: scaleY(1.2);
  display: inline-block;
  margin-right: 0.2rem;
}